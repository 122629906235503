import React from "react";
import { Row, Col, Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const ReserveTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF December 16th, 2019.</Title>
              <Title level={2}>SpotOn Reserve</Title>
              <Title level={1}>
                Software Terms and Conditions of Use - License Agreement
              </Title>
              <p>
                THE SOFTWARE IS LICENSED, NOT SOLD. YOUR USE OF THE SOFTWARE (AS
                SPECIFIED BELOW) IS SUBJECT TO THIS LIMITED WARRANTY AND LICENSE
                AGREEMENT FOR END USERS AND THE TERMS AND CONDITIONS SET FORTH
                BELOW (“AGREEMENT”). For purposes of this Agreement “Software”
                means all software programs and services made available by
                SpotOn Transact Inc., its parents, its subsidiaries and
                affiliates (collectively “SpotOn Reserve”) including, but not
                limited to mobile app, downloadable/ installable apps for
                personal computers, tablets and mobile devices, and programs and
                services accessed by means of a browser or other online
                communication method. Software also includes updates and
                upgrades as well as accompanying manual(s), packaging and other
                written files, electronic or on-line materials or documentation,
                and any and all copies of such software and its materials.
              </p>
              <p>
                BY INSTALLING, USING OR ACCESSING THE SOFTWARE OR ANY MATERIALS
                INCLUDED WITH THE SOFTWARE, YOU HEREBY ACCEPT THE TERMS OF THIS
                AGREEMENT. If you do not agree to the terms of this Agreement,
                do not install, use or access the Software.
              </p>
              <Title level={3}>LICENSE</Title>
              <p>
                Subject to this Agreement and its terms and conditions, SpotOn
                Reserve hereby grants you a non-exclusive, non-transferable,
                non-sub- licensable, limited right and license to use one copy
                of the Software for your restaurant location on your personal
                computer or tablets, unless otherwise specified in the Software
                documentation. Your acquired rights are subject to your
                compliance with this Agreement. The term of your license under
                this Agreement shall commence on the date that you install or
                otherwise use the Software and ends on the earlier date of
                either your disposal of the Software or SpotOn Reserve’s
                termination of this Agreement. Your license terminates
                immediately if you attempt to circumvent any technical
                protection measures used in connection with the Software, or do
                not submit payment for use of the Software as agreed. The
                Software is being licensed to you and you hereby acknowledge
                that no title or ownership in the Software is being transferred
                or assigned and this Agreement should not be construed as a sale
                of any rights in the Software. All rights not specifically
                granted under this Agreement are reserved by SpotOn Reserve and
                its licensors.
              </p>
              <Title level={3}>LICENSE CONDITIONS</Title>
              <p>
                You agree not to: (i) commercially exploit the Software; (ii)
                distribute, lease, license, sell, rent, lend, convey or
                otherwise transfer or assign the Software, any passwords or
                usernames or any copies of the Software, without the express
                prior written consent of SpotOn Reserve or as set forth in this
                Agreement; (iii) make a copy of the Software or any part thereof
                (other than as set forth herein); (iv) make a copy of the
                Software publicly available or available on a network for use or
                download by multiple users; (v) except as otherwise specifically
                provided by the Software or this Agreement, use or install the
                Software (or permit others to do same) on a network, for on-line
                use, or on more than one restaurant location at the same time;
                (vi) use or copy the Software at a computer center or any other
                location-based site; provided, that SpotOn Reserve may offer you
                a separate site license agreement to make the Software available
                for commercial use; (vii) reverse engineer, decompile,
                disassemble, translate, prepare derivative works based on or
                otherwise modify the Software, in whole or in part; (viii)
                remove, obscure or modify any copyright, trademark or other
                proprietary rights notices, marks or labels contained on or
                within the Software, falsify or delete any author attributions,
                legal notices or other labels of the origin or source of the
                material; (ix) misrepresent the source of ownership of the
                Software; (x) transport, export or re-export (directly or
                indirectly) into any country forbidden to receive such Software
                by any U.S. or other export laws or accompanying regulations or
                otherwise violate such laws or regulations, that may be amended
                from time to time; or (xi) scrape, build databases or otherwise
                create permanent copies of content returned from the Software.
                The Software may include measures to control access to the
                Software, prevent unauthorized copies, or otherwise attempt to
                prevent anyone from exceeding the limited rights and licenses
                granted under this Agreement. Only Software subject to a valid
                license can be used to access online services, and download
                updates and patches. You may not interfere with such access
                control measures or attempt to disable or circumvent such
                security features. If you disable or otherwise tamper with the
                technical protection measures, the Software will not function
                properly. The Software may require an Internet connection to
                access the Software or its internet- based features,
                authenticate the Software, or perform other functions. In order
                for certain features of the Software to operate properly, you
                may be required to have and maintain (a) an adequate internet
                connection and/or (b) a valid and active account with an online
                service as set forth in the Software documentation. If you do
                not maintain such accounts, then the Software or certain
                features of the Software may not operate or may cease to
                function properly, either in whole or in part (see SpotOn
                Reserve Terms of Use).
              </p>
              <Title level={3}>TERM</Title>
              <p>
                The term of this Agreement is for twelve (12) consecutive months
                from the Starting Date above. Payment is collected each month,
                in advance, electronically through ACH or direct charge to a
                designated credit card. Once this initial term has been reached,
                this Agreement will Auto renew for 12 months.
              </p>
              <Title level={3}>OWNERSHIP</Title>
              <p>
                SpotOn Reserve retains all right, title and interest to the
                Software, including, but not limited to, all copyrights,
                trademarks, trade secrets, trade names, proprietary rights,
                patents, titles, computer codes, audiovisual effects, themes,
                characters, character names, stories, dialog, settings, artwork,
                sound effects, musical works, and moral rights whether
                registered or not and all applications thereof. The Software is
                protected by applicable laws and treaties throughout the world.
                Unless expressly authorized by mandatory legislation, the
                Software may not be copied, reproduced or distributed in any
                manner or medium, in whole or in part, without prior written
                consent from SpotOn Reserve. All rights not expressly granted to
                you herein are reserved by SpotOn Reserve.
              </p>
              <Title level={3}>
                INFORMATION COLLECTION AND USE; PRIVACY POLICY
              </Title>
              <p>
                By installing, accessing or using the Software, you consent to
                these information collection and usage terms, including (where
                applicable) the transfer of data into a country outside of the
                European Union and/or the European Economic Area or the United
                States of America. SpotOn Reserve respects your privacy rights
                and recognizes the importance of protecting any information
                collected about you. SpotOn Reserve’s privacy policy as amended
                from time to time is available at www.SpotOnReserve.com/privacy
                (“Privacy Policy”) and applicable to this Agreement. SpotOn
                Reserve’s Privacy Policy defines how, why and to which extent
                SpotOn Reserve collects and uses personal and non-personal
                information in relation to SpotOn Reserve’s products and
                services. By installing, accessing or using the Software you
                explicitly agree with the terms and conditions of SpotOn
                Reserve’s Privacy Policy (see SpotOn Reserve Privacy Policy).
              </p>
              <Title level={3}>WARRANTY</Title>
              <p>
                TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THE
                SOFTWARE IS PROVIDED TO YOU “AS IS,” WITH ALL FAULTS, WITHOUT
                WARRANTY OF ANY KIND, WITHOUT PERFORMANCE ASSURANCES OR
                GUARANTEES OF ANY KIND, AND YOUR USE IS AT YOUR SOLE RISK. THE
                ENTIRE RISK OF SATISFACTORY QUALITY AND PERFORMANCE RESIDES WITH
                YOU. SPOTON RESERVE, SPOTON RESERVE’S LICENSORS AND CHANNEL
                PARTNERS DO NOT MAKE, AND HEREBY DISCLAIM, ANY AND ALL EXPRESS,
                IMPLIED OR STATUTORY WARRANTIES, INCLUDING IMPLIED WARRANTIES OF
                CONDITION, UNINTERRUPTED USE, MERCHANTABILITY, SATISFACTORY
                QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
                THIRD PARTY RIGHTS, AND WARRANTIES (IF ANY) ARISING FROM A
                COURSE OF DEALING, USAGE, OR TRADE PRACTICE. SPOTON RESERVE,
                SPOTON RESERVE’S LICENSORS AND CHANNEL PARTNERS DO NOT WARRANT
                AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE SOFTWARE; THAT
                THE SOFTWARE WILL MEET YOUR REQUIREMENTS; THAT OPERATION OF THE
                SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT THE
                SOFTWARE WILL INTEROPERATE OR BE COMPATIBLE WITH ANY OTHER
                SOFTWARE OR THAT ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
                NO ORAL OR WRITTEN ADVICE PROVIDED BY SPOTON RESERVE, SPOTON
                RESERVE’S LICENSORS AND CHANNEL PARTNERS OR ANY AUTHORIZED
                REPRESENTATIVE SHALL CREATE A WARRANTY. SOME JURISDICTIONS DO
                NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES
                OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A
                CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
                APPLY ONLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
                APPLICABLE JURISDICTION.
              </p>
              <Title level={3}>LIMITATION OF LIABILITY</Title>
              <p>
                IN NO EVENT WILL SPOTON RESERVE, SPOTON RESERVE’S LICENSORS OR
                CHANNEL PARTNERS BE LIABLE FOR SPECIAL, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES RESULTING FROM POSSESSION, ACCESS, USE OR
                MALFUNCTION OF THE SOFTWARE, INCLUDING BUT NOT LIMITED TO,
                DAMAGES TO PROPERTY, LOSS OF GOODWILL, COMPUTER FAILURE OR
                MALFUNCTION AND, TO THE EXTENT PERMITTED BY LAW, DAMAGES FOR
                PERSONAL INJURIES, PROPERTY DAMAGE, LOST PROFITS OR PUNITIVE
                DAMAGES FROM ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO
                THIS AGREEMENT OR THE SOFTWARE, WHETHER ARISING IN TORT
                (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE
                AND WHETHER OR NOT SPOTON RESERVE, SPOTON RESERVE’S LICENSORS OR
                CHANNEL PARTNERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. FOR PURPOSES OF THIS SECTION 5, SPOTON RESERVE’S
                LICENSORS AND CHANNEL PARTNERS ARE THIRD PARTY BENEFICIARIES TO
                THE LIMITATIONS OF LIABILITY SPECIFIED HEREIN AND THEY MAY
                ENFORCE THIS AGREEMENT AGAINST YOU. BECAUSE SOME
                STATES/COUNTRIES DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY,
                THIS LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
                PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. THIS LIMITATION
                OF LIABILITY SHALL NOT BE APPLICABLE SOLELY TO THE EXTENT THAT
                ANY SPECIFIC PROVISION OF THIS LIMITATION OF LIABILITY IS
                PROHIBITED BY ANY FEDERAL, STATE, OR MUNICIPAL LAW, WHICH CANNOT
                BE PREEMPTED. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS,
                AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
                JURISDICTION. IN NO EVENT SHALL SPOTON RESERVE, SPOTON RESERVE’S
                LICENSORS OR CHANNEL PARTNERS’ LIABILITY FOR ALL DAMAGES (EXCEPT
                AS REQUIRED BY APPLICABLE LAW) EXCEED THE ACTUAL PRICE PAID BY
                YOU FOR USE OF THE SOFTWARE OR FIFTY DOLLARS ($50 USD),
                WHICHEVER LESS.
              </p>
              <Title level={3}>TERMINATION</Title>
              <p>
                This Agreement will terminate automatically if you fail to
                comply with its terms and conditions or do not submit timely
                payment for use of the Software. In such event, you must cease
                and desist from accessing any service components of the
                Software.
              </p>
              <Title level={3}>CANCELLATION</Title>
              <p>
                This Agreement may be cancelled at any time with a 30-day
                written notice.
              </p>
              <Title level={3}>EQUITABLE REMEDIES</Title>
              <p>
                You hereby agree that if the terms of this Agreement are not
                specifically enforced, SpotOn Reserve will be irreparably
                damaged, and therefore you agree that SpotOn Reserve shall be
                entitled, without bond, other security or proof of damages, to
                appropriate equitable remedies with respect to your breach of
                any of the terms of this Agreement, in addition to any other
                available remedies.
              </p>
              <Title level={3}>INDEMNITY</Title>
              <p>
                You agree to indemnify, defend and hold SpotOn Reserve, its
                partners, affiliates, contractors, officers, directors,
                employees and agents harmless from and against any and all
                damages, losses and expenses arising directly or indirectly
                from: (i) your acts and omissions to act in using the Software
                pursuant to the terms of the Agreement; or (ii) your breach of
                this Agreement.
              </p>
              <Title level={3}>MISCELLANEOUS</Title>
              <p>
                This Agreement represents the complete agreement concerning this
                license between the parties and supersedes all prior agreements
                and representations between them. It may be amended only by a
                written document executed by both parties. If any provision of
                this Agreement is held to be unenforceable for any reason, such
                provision shall be reformed only to the extent necessary to make
                it enforceable and the remaining provisions of this Agreement
                shall not be affected.
              </p>
              <Title level={3}>GOVERNING LAW AND DISPUTE RESOLUTION</Title>
              <p>
                This Agreement will be governed by the laws of the United States
                and the State of the Delaware without reference to its choice of
                law rules. The United Nations Convention for the International
                Sale of Goods shall not apply.
              </p>
              <p>
                Any dispute, controversy or claim arising out of or relating to
                this Agreement or the breach, termination or validity thereof
                shall be finally settled at SpotOn Reserve’s discretion (i) at
                your domicile’s competent courts; or (ii) by arbitration in
                accordance with the Arbitration Rules of International Chamber
                of Commerce. The arbitration shall be conducted in Delaware,
                United States, in the English language. The award shall be final
                and binding on the parties. BY ENTERING INTO THIS AGREEMENT, YOU
                EXPRESSLY WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION
                AGAINST SPOTON RESERVE AND ITS PARTNERS.
              </p>
              <Title level={2}>Terms of Use</Title>
              <p>
                These SpotOn Reserve Terms of Use (this “Agreement”) apply to
                your access or use of the SpotOn Reserve websites, mobile sites,
                and applications (collectively, the “SpotOn Reserve Sites”) and
                the content, features, and services (the “Services”) made
                available by SpotOn Reserve.
              </p>
              <p>
                In this Agreement, “SpotOn Reserve” and “we” mean the SpotOn
                Reserve company that is providing the Services to you, and with
                whom you are entering into this Agreement. The terms “User” and
                “you” mean any user of the Services. This Agreement incorporates
                SpotOn Reserve’s standard policies, procedures, and terms and
                conditions for use of the Services that are referenced by name
                or by links in this Agreement (collectively, the “SpotOn Reserve
                Policies”).
              </p>
              <p>
                By accessing or using the Services, (1) you acknowledge that you
                have read, understand, and agree to be bound by this Agreement,
                and (2) you represent and warrant that you are of legal age and
                not prohibited by law from accessing or using the Services. THIS
                AGREEMENT CONTAINS, AMONG OTHER THINGS, AN ARBITRATION PROVISION
                CONTAINING A CLASS ACTION WAIVER APPLICABLE TO USERS WHO ARE
                RESIDENTS OF THE UNITED STATES (INCLUDING ITS POSSESSIONS AND
                TERRITORIES), AND CANADA.
              </p>
              <p>
                SpotOn Reserve may update or revise this Agreement (including
                any SpotOn Reserve Policies) from time to time. You agree that
                you will review this Agreement periodically. You are free to
                decide whether or not to accept a modified version of this
                Agreement, but accepting this Agreement, as modified, is
                required for you to continue using the Services. Except as
                otherwise expressly stated by SpotOn Reserve, any use of the
                Services e.g., the use of the Reservation Services (as defined
                herein) or Waitlist Services (as defined herein), is subject to
                the version of this Agreement in effect at the time of use.
              </p>
              <p>
                Nothing contained within these terms shall supersede or nullify
                the terms related to using additional SpotOn products.
              </p>
              <Title level={3}>1. Restaurant Reservations.</Title>
              <p>
                SpotOn Reserve makes available restaurant reservation services
                (the “Reservation Services”) and waitlist services (the
                “Waitlist Services”) through the SpotOn Reserve Sites to User
                for the purpose of assisting User in securing dining
                reservations or joining a waitlist at participating third-party
                restaurants (each, a “Restaurant”). In response to a User’s
                online request for a Restaurant reservation or to join a
                waitlist through the SpotOn Reserve Site, SpotOn Reserve
                contacts the Restaurant’s computerized database of reservations
                and waitlists, as applicable. The availability of reservations,
                estimated wait time or place in line on a waitlist is determined
                at the time of User’s query and based on information provided to
                SpotOn Reserve by the Restaurant. Once a reservation or waitlist
                request is made by User through the SpotOn Reserve Site, SpotOn
                Reserve will provide confirmation of the reservation or waitlist
                status to User by email or other electronic messages as has been
                agreed to by User. By using the Reservation Services or Waitlist
                Services, User agrees to receive reservation and waitlist
                confirmations, updates, modifications and/or cancellations by
                email or other electronic messages.
              </p>
              <Title level={3}>2. No-Show Policy.</Title>
              <p>
                SpotOn Reserve is committed to providing superior quality
                services to Users and Restaurants. To assist us in maintaining a
                consistently high level of service for the Restaurants and their
                patrons, Users must cancel any reservations that they will be
                unable to honor at least 30 minutes in advance of the
                reservation. You may cancel your reservation via the SpotOn
                Reserve Site or by calling the Restaurant directly. Some
                Restaurants may require a debit or credit card number to
                finalize your reservation. In order to use the Reservation
                Services for these Restaurants, you must provide valid debit or
                credit card information. To confirm that the debit or credit
                card information you have provided is accurate, we may place a
                temporary authorization on your debit or credit card at the time
                you provide your debit or credit card information. After we
                verify that your debit or credit card information is accurate,
                usually within a few days, the authorization will be removed.
                You may be required to cancel your reservation in accordance
                with the Restaurant’s cancellation policy, which is disclosed at
                the time the reservation is made. SpotOn Reserve uses this debit
                or credit card information as described in our privacy policy
                and shall have no liability for any charges made to the debit or
                credit card account for any failure to cancel your reservation
                in accordance with a Restaurant’s cancellation policy.
              </p>
              <p>
                If you are unable to keep your reservation, the Restaurant may
                indicate that you were a no-show. Upon arriving at the
                Restaurant, it is the User’s responsibility to notify the host
                that the User has a reservation. User agrees that all final
                no-show determinations will be made by the applicable Restaurant
                in its sole discretion.
              </p>
              <p>
                If you wish to remove yourself from a waitlist you have joined
                through the Waitlist Services, you can do so by managing your
                place in line through the SpotOn Reserve Site or by calling the
                Restaurant. Failure to appear at the Restaurant in a timely
                manner may result in the Restaurant bypassing your place on the
                waitlist for other guests or removing you from the waitlist
                entirely.
              </p>
              <Title level={3}>3. Usage Guidelines.</Title>
              <p>
                User agrees to use the Reservation Services or Waitlist Services
                only to book reservations or join waitlists at Restaurants and
                then honor those reservations or waitlist requests by arriving
                at the Restaurants on time and ordering and paying for meals.
                User further agrees not to book more than one reservation or
                join more than one waitlist for User’s personal use during any
                one meal time (e.g., lunch, dinner, etc.). Resale or attempted
                resale of reservations or waitlist spots is prohibited and is
                grounds for, among other things, cancellation of your
                reservations, removal from the waitlist or termination of your
                access to the Services.
              </p>
              <Title level={3}>4. Privacy Policy.</Title>
              <p>
                SpotOn Reserve is committed to helping you safeguard your
                privacy online. Please review our Privacy Policy for details
                about how we collect, use, and disclose information in
                connection with the Services.
              </p>
              <Title level={3}>5. Your Account.</Title>
              <p>
                You are required create an account with SpotOn Reserve through
                the SpotOn Reserve Site (“Account”) in order to use the
                Reservation Services or Waitlist Services. When registering for
                an Account, you must provide true, accurate, current, and
                complete data about yourself on the SpotOn Reserve registration
                form (“Registration Data”). You also agree to promptly update
                the Registration Data to keep it true, accurate, current, and
                complete. You are solely responsible for maintaining the
                confidentiality of your Account and the information in your
                Account, and, except as otherwise required by applicable law,
                you are solely responsible for all use of your Account, whether
                or not authorized by you. You agree to immediately notify SpotOn
                Reserve of any unauthorized use of your Account or any other
                breach of security related to your use of the Services.
              </p>
              <Title level={3}>6. Communications from SpotOn Reserve.</Title>
              <p>
                If you use our Sites and Services, SpotOn Reserve may
                communicate with you via electronic messages, including email,
                text message/SMS, or mobile push notifications in accordance
                with our Privacy Policy.
              </p>
              <Title level={3}>7. Technical Requirements. </Title>
              <p>
                Use of the Services requires Internet access through your
                computer or mobile device. You are responsible for all mobile
                carrier data or text message charges resulting from your use of
                the Services, including from any notifications provided by the
                Services. In order to use the text message based services, you
                must maintain an active account with a carrier of electronic
                communications through mobile devices and you may not use a
                prepaid cellular phone to access such text message services.
                SpotOn Reserve does not guarantee that the Services will be
                compatible with all devices or will be supported by all mobile
                carriers. You may be required to have JavaScript (or similar
                technologies) enabled to use the SpotOn Reserve Site, and some
                features and portions of the SpotOn Reserve Site (including, but
                not limited to, making, modifying, or canceling reservations)
                may not be accessible with JavaScript disabled.
              </p>
              <Title level={3}>8. Modifications to Services.</Title>
              <p>
                SpotOn Reserve reserves the right, in its sole discretion, to
                modify the Services from time to time and without notice,
                including, without limitation, by removing, adding, or modifying
                portions of the SpotOn Reserve Sites, Restaurants, and/or
                Merchants. SpotOn Reserve shall have no liability to you for any
                of the foregoing actions. If you object to any such changes,
                your sole recourse shall be to cease using the Services.
                Continued use of the Services following any such changes shall
                indicate your acknowledgment of such changes and satisfaction
                with all the Services.
              </p>
              <Title level={3}>
                9. Intellectual Property Rights and Grant of Rights to User.
              </Title>
              <p>
                The features, information, and materials provided and depicted
                through the Services are protected by copyright, trademark,
                patent, and other intellectual property laws. All text,
                graphical content, video, data, and other content made available
                through the Services (collectively, the “SpotOn Reserve
                Content”) are provided to User by SpotOn Reserve or its partners
                or licensors solely to support User’s permitted use of the
                Services. The SpotOn Reserve Content may be modified from time
                to time by SpotOn Reserve in its sole discretion. Except as
                expressly set forth herein, no license is granted to User for
                any other purpose, and any other use of the Services or the
                SpotOn Reserve Content by User shall constitute a material
                breach of this Agreement. SpotOn Reserve and its partners or
                licensors retain all rights in the Services and SpotOn Reserve
                Content and any associated patents, trademarks, copyrights, mask
                work rights, trade secrets, or other intellectual property
                rights. No license, right, or interest in any trademarks of
                SpotOn Reserve or any third party is granted under this
                Agreement.
              </p>
              <Title level={3}>10. Use Restrictions.</Title>
              <p>
                The Services and SpotOn Reserve Content are offered solely for
                User’s personal use for the purposes described in this
                Agreement. Any and all other uses are prohibited. SpotOn Reserve
                expressly reserves all its rights and remedies under applicable
                laws (including state, federal, and provincial/territorial
                laws). SpotOn Reserve reserves the right, in its sole
                discretion, to refuse service, terminate Accounts, remove or
                edit content, cancel reservations, or deny access to the
                Services. You agree not to (and not to allow any third party
                to): (1) use any deep-link, robot, spider, scraper, or other
                automatic or manual device, process, or means to access, copy,
                search, or monitor any portion of the Services or SpotOn Reserve
                Content, except as expressly authorized by SpotOn Reserve; (2)
                take any action that imposes or may impose (in SpotOn Reserve’s
                sole determination) an unreasonable or a disproportionately
                large load on the Services or SpotOn Reserve’s infrastructure;
                (3) utilize any device, software, or routine that will interfere
                or attempt to interfere with the functionality of the Services;
                (4) rent, lease, copy, provide access to or sublicense any
                portion of the Services or SpotOn Reserve Content to a third
                party; (5) use any portion of the Services or SpotOn Reserve
                Content to provide, or incorporate any portion of the Services
                or SpotOn Reserve Content into, any product or service provided
                to a third party; (6) reverse engineer, decompile, disassemble,
                or otherwise seek to obtain the source code or non-public APIs
                to the Services, except to the extent expressly permitted by
                applicable law (and then only upon advance notice to SpotOn
                Reserve); (7) modify any Services or SpotOn Reserve Content or
                create any derivative product from any of the foregoing; (8)
                remove or obscure any proprietary or other notices contained in
                the Services or SpotOn Reserve Content; (9) use the Services or
                SpotOn Reserve Content for any illegal purpose; or (10) publicly
                disseminate information regarding the performance of the
                Services or SpotOn Reserve Content or access or use the Services
                or SpotOn Reserve Content for competitive analysis or
                benchmarking purposes. Although the SpotOn Reserve Sites may be
                accessible worldwide, not all features or services discussed,
                referenced, provided or offered through or on the SpotOn Reserve
                Site are available to all persons or in all geographic
                locations, or appropriate or available for use outside the
                United States. SpotOn Reserve reserves the right to limit, in
                its sole discretion, the provision and quantity of any feature
                or service to any person or geographic area.
              </p>
              <Title level={3}>11. United States Government End Users.</Title>
              <p>
                The Services constitute a “commercial item” as defined at 48
                C.F.R. 2.101, consisting of “commercial computer software” and
                “commercial computer software documentation” as such terms are
                used in 48 C.F.R. 12.212. Consistent with 48 C.F.R. 12.212 and
                48 C.F.R. 227.7202-1 through 227.7202-4, all U.S. Government end
                users acquire any SpotOn Reserve Site with only those rights set
                forth therein.
              </p>
              <Title level={3}>12. Export Control. </Title>
              <p>
                You may not use, export, or re-export any SpotOn Reserve Site or
                other aspects of the Services (or any copy or adaptation of the
                foregoing) in violation of applicable law, including, without
                limitation, United States and foreign export laws and
                regulations. You represent and warrant that you are not located
                in a country that is subject to a U.S. Government embargo or
                that has been designated by the U.S. Government as a “terrorist
                supporting” country and that you are not listed on any U.S.
                Government list of prohibited or restricted parties.
              </p>
              <Title level={3}>13. Termination.</Title>
              <p>
                SpotOn Reserve may suspend your ability to use all or any
                element of the Services or may terminate this Agreement
                effective immediately, without notice or explanation. Without
                limiting the foregoing, SpotOn Reserve may suspend your access
                to the Services if we believe you to be in violation of any part
                of this Agreement (including any SpotOn Reserve Policies). After
                any suspension or termination, you may or may not be granted
                permission to use the Services or re-establish an Account. You
                agree that SpotOn Reserve shall not be liable to you for any
                termination of this Agreement or for any effects of any
                termination of this Agreement. You are always free to
                discontinue your use of the Services at any time. You understand
                that any termination of your Account may involve deletion of any
                content stored in your Account for which SpotOn Reserve will
                have no liability whatsoever.
              </p>
              <Title level={3}>14. Indemnity.</Title>
              <p>
                You agree to indemnify, hold harmless, and (at SpotOn Reserve’s
                request) defend SpotOn Reserve, its affiliates, and its and
                their representatives, agents, directors, managers, officers,
                employees, and shareholders (collectively, the “SpotOn Reserve
                Parties”) from and against all claims resulting from (1) your
                use of the Services, or (2) any breach or alleged breach by you
                of this Agreement.
              </p>
              <Title level={3}>15. Liability Limitations.</Title>
              <p>
                EXCEPT AS EXPRESSLY SPECIFIED HEREIN, IN NO EVENT SHALL THE
                SPOTON RESERVE PARTIES BE LIABLE FOR ANY INJURIES, LOSSES,
                CLAIMS, OR DIRECT DAMAGES OR ANY SPECIAL, EXEMPLARY, PUNITIVE,
                INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER BASED
                IN CONTRACT, TORT, OR OTHERWISE, AND EVEN IF ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES, WHICH ARISE OUT OF OR ARE ANY WAY
                CONNECTED WITH (1) THIS AGREEMENT (INCLUDING ANY CHANGES
                THERETO), (2) ANY USE OF THE SPOTON RESERVE SITE, SERVICES, THE
                SPOTON RESERVE CONTENT, OR THE USER CONTENT, (3) ANY FAILURE OR
                DELAY (INCLUDING, BUT NOT LIMITED TO, THE USE OR INABILITY TO
                USE ANY COMPONENT OF ANY OF THE SERVICES), OR (4) YOUR VISIT TO
                ANY RESTAURANT OR THE PERFORMANCE, NON-PERFORMANCE, CONDUCT, OR
                POLICIES OF ANY RESTAURANT OR MERCHANT IN CONNECTION WITH THE
                SERVICES. IN ADDITION, YOU SPECIFICALLY UNDERSTAND AND AGREE
                THAT ANY THIRD PARTY DIRECTING YOU TO THE SPOTON RESERVE SITE BY
                REFERRAL, LINK, OR ANY OTHER MEANS IS NOT LIABLE TO USER FOR ANY
                REASON WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DAMAGES OR
                LOSS ASSOCIATED WITH THE USE OF THE SERVICES OR THE SPOTON
                RESERVE CONTENT. SPOTON RESERVE IS NEITHER AN AGENT OF NOR
                OTHERWISE ASSOCIATED WITH ANY RESTAURANT FOR WHICH A USER HAS
                MADE A RESERVATION OR CLAIMED AN OFFER OR PROMOTION.
              </p>
              <p>
                IF THE DISCLAIMER OF DIRECT DAMAGES ABOVE IS NOT ENFORCEABLE AT
                LAW, YOU EXPRESSLY AGREE THAT OUR LIABILITY TO YOU (FOR AY CAUSE
                WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION) WILL AT ALL
                TIMES BE LIMITED TO FIFTY US DOLLARS ($50).
              </p>
              <p>
                You and SpotOn Reserve understand and agree that the
                disclaimers, exclusions, and limitations in this Section 15 and
                in Section 16 are essential elements of this Agreement and that
                they represent a reasonable allocation of risk. In particular,
                you understand that SpotOn Reserve would be unable to make the
                Services available to you except on these terms and agree that
                this Agreement will survive and apply even if any limited remedy
                specified in this Agreement is found to have failed of its
                essential purpose.
              </p>
              <Title level={3}>16. Disclaimer of Warranties.</Title>
              <p>
                YOU UNDERSTAND THAT USE OF THE SERVICES IS AT YOUR OWN RISK AND
                SPOTON RESERVE CANNOT GUARANTEE THAT THE SERVICES WILL BE
                UNINTERRUPTED OR ERROR-FREE. THE SERVICES, ALL SPOTON RESERVE
                CONTENT, AND ANY OTHER INFORMATION, PRODUCTS, AND MATERIALS
                CONTAINED IN OR ACCESSED THROUGH THE SERVICES, ARE PROVIDED TO
                USER ON AN “AS IS” BASIS AND WITHOUT WARRANTY OF ANY KIND.
                SPOTON RESERVE EXPRESSLY DISCLAIMS ALL REPRESENTATIONS,
                WARRANTIES, CONDITIONS, OR INDEMNITIES, EXPRESS OR IMPLIED,
                INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, OR
                ANY WARRANTY ARISING FROM A COURSE OF DEALING, PERFORMANCE, OR
                TRADE USAGE. SPOTON RESERVE DOES NOT WARRANT THAT YOUR USE OF
                THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT SPOTON
                RESERVE WILL REVIEW THE INFORMATION OR MATERIALS MADE AVAILABLE
                THROUGH THE SERVICES FOR ACCURACY OR THAT IT WILL PRESERVE OR
                MAINTAIN ANY SUCH INFORMATION OR MATERIALS WITHOUT LOSS. SPOTON
                RESERVE SHALL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE
                FAILURES, OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND
                ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE
                REASONABLE CONTROL OF SPOTON RESERVE.
              </p>
              <p>
                THE FOREGOING DISCLAIMERS APPLY TO THE MAXIMUM EXTENT PERMITTED
                BY LAW. YOU MAY HAVE OTHER STATUTORY RIGHTS. HOWEVER, THE
                DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, SHALL BE
                LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              </p>
              <Title level={3}>
                17. Third-Party Websites, Applications and Services.
              </Title>
              <p>
                The Services may contain hypertext links to websites and
                applications operated by parties other than SpotOn Reserve. Such
                hypertext links are provided for User’s reference only, and
                SpotOn Reserve does not control such websites and is not
                responsible for their content. SpotOn Reserve’s inclusion of any
                hypertext links to such websites or applications does not imply
                any endorsement of the material on such websites or applications
                or any association with their operators. SpotOn Reserve assumes
                no liability whatsoever for any such third-party websites,
                applications or any content, features, products, or services
                made available through such third-party websites or
                applications. Additionally, Apple Inc., Google, Inc., or
                Microsoft Corporation will be a third-party beneficiary to this
                contract if you access the Services using applications developed
                for Apple iOS, Android, or Microsoft Windows devices,
                respectively. These third party beneficiaries are not parties to
                this contract and are not responsible for the provision or
                support of the Services in any manner. Your access to the
                Services using these devices is subject to terms set forth in
                the applicable third party beneficiary&quot;s terms of service,
                including any license transferability and other usage rules
                therein.
              </p>
              <Title level={3}>18. Release.</Title>
              <p>
                Restaurants and Merchants are solely responsible for their
                interactions with you and any and all claims, injuries,
                illnesses, damages, liabilities, and costs (“Claims”) suffered
                by you as a result of your (or such recipient’s) interaction
                with or visit to any Restaurant or Merchant or from any
                promotion, offer, product or service of any Restaurant or
                Merchant. Users must resolve all disputes directly with
                Restaurants. To the maximum extent permitted by applicable law,
                you hereby release the SpotOn Reserve Parties from any and all
                such Claims. IN CONNECTION WITH THE FOREGOING, IF YOU ARE A
                CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
                SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND
                TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
                IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH,
                IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER
                SETTLEMENT WITH THE DEBTOR.” You hereby expressly waive and
                relinquish all rights and benefits under that section and any
                law of any jurisdiction of similar effect with respect to the
                release of any unknown or unsuspected claims you may have
                against the SpotOn Reserve Parties pertaining to the subject
                matter of this Section 18.
              </p>
              <Title level={3}>19. Severability.</Title>
              <p>
                If any of the provisions, or portions thereof, of this Agreement
                are found to be invalid under any applicable statute or rule of
                law, then, that provision (or portion thereof) notwithstanding,
                this Agreement shall remain in full force and effect and such
                provision or portion thereof shall be deemed omitted.
              </p>
              <Title level={3}>
                6.13. Accuracy, Currency, and Retention of Data.
              </Title>
              <p>
                Client is responsible for the accuracy of the information Client
                (including each Location) provides SpotOn relating to Users and
                for updating such information as may be necessary to keep it
                current. Client will not rely on the information it transmits to
                SpotOn as an archive or backup, and will be responsible for
                retaining copies of all information Client sends SpotOn for
                which Client may have a need or wish to use. SpotOn makes no
                representations or warranties about its ability to return
                information relating to Users or the accuracy or completeness of
                any such information if provided to Client, upon Client’s
                reasonable request and SpotOn’s subsequent agreement to provide
                such data.
              </p>
              <Title level={3}>20. Assignment.</Title>
              <p>
                This Agreement and the rights granted and obligations undertaken
                hereunder may not be transferred, assigned, or delegated in any
                manner by User, but may be freely transferred, assigned, or
                delegated by SpotOn Reserve.
              </p>
              <Title level={3}>21. Waiver.</Title>
              <p>
                Any waiver of any provision of this Agreement, or a delay by any
                party in the enforcement of any right hereunder, shall neither
                be construed as a continuing waiver nor create an expectation of
                non-enforcement of that or any other provision or right.
              </p>
              <Title level={3}>
                22. ARBITRATION AGREEMENT AND JURY TRIAL WAIVER, CLASS ACTION
                WAIVER, AND FORUM SELECTION CLAUSE
              </Title>
              <p>
                If you are a resident of the United States (including its
                possessions and territories) or Canada, you agree that any and
                all controversies, disputes, demands, counts, claims, or causes
                of action (including the interpretation and scope of this
                clause, and the arbitrability of the controversy, dispute,
                demand, count, claim, or cause of action) between you and the
                SpotOn Reserve Parties or their successors or assigns shall
                exclusively be settled through binding and confidential
                arbitration.
              </p>
              <p>
                Arbitration shall be subject to the Federal Arbitration Act and
                not any state or provincial/territorial arbitration law. The
                arbitration shall be conducted before one commercial arbitrator
                with substantial experience in resolving commercial contract
                disputes from the American Arbitration Association (“AAA”). As
                modified by this Agreement, and unless otherwise agreed upon by
                the parties in writing, the arbitration will be governed by the
                AAA’s Commercial Arbitration Rules and, if the arbitrator deems
                them applicable, the Supplementary Procedures for Consumer
                Related Disputes (collectively, the “Rules and Procedures”).
              </p>
              <p>
                In the case of arbitration and where permitted by law, you are
                thus agreeing to GIVE UP YOUR RIGHT TO GO TO COURT to assert or
                defend your rights EXCEPT for matters that you file in small
                claims court. Your rights will be determined by a NEUTRAL
                ARBITRATOR and NOT a judge or jury. You are entitled to a FAIR
                HEARING, BUT the arbitration procedures are SIMPLER AND MORE
                LIMITED THAN RULES APPLICABLE IN COURT. Arbitrator decisions are
                as enforceable as any court order and are subject to VERY
                LIMITED REVIEW BY A COURT.
              </p>
              <p>
                In the case of arbitration and where permitted by law, you and
                SpotOn Reserve must abide by the following rules: (1) ANY CLAIMS
                BROUGHT BY YOU OR SPOTON RESERVE MUST BE BROUGHT IN THE PARTY’S
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; (2) THE
                ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS,
                MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR
                CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF; (3) in
                the event that you are able to demonstrate that the costs of
                arbitration will be prohibitive as compared to costs of
                litigation, SpotOn Reserve will pay as much of your filing and
                hearing fees in connection with the arbitration as the
                arbitrator deems necessary to prevent the arbitration from being
                cost-prohibitive as compared to the cost of litigation; (4)
                SpotOn Reserve also reserves the right in its sole and exclusive
                discretion to assume responsibility for all of the costs of the
                arbitration; (5) the arbitrator shall honor claims of privilege
                and privacy recognized at law; (6) the arbitration shall be
                confidential, and neither you nor we may disclose the existence,
                content or results of any arbitration, except as may be required
                by law or for purposes of enforcement of the arbitration award;
                (7) the arbitrator may award any individual relief or individual
                remedies that are permitted by applicable law; and (8) each side
                pays its own attorneys’ fees and expenses unless there is a
                statutory provision that requires the prevailing party to be
                paid its fees and litigation expenses, and, in such instance,
                the fees and costs awarded shall be determined by the applicable
                law.
              </p>
              <p>
                Notwithstanding the foregoing, either you or SpotOn Reserve may
                bring an individual action in small claims court. Further,
                claims of defamation, violation of the Computer Fraud and Abuse
                Act, and infringement or misappropriation of the other party’s
                patent, copyright, trademark, or trade secret shall not be
                subject to this arbitration agreement. Such claims shall be
                exclusively brought in the state or federal courts located in
                Sacramento County, California. Additionally, notwithstanding
                this agreement to arbitrate, either party may seek emergency
                equitable relief before the state or federal courts located in
                Sacramento County, California in order to maintain the status
                quo pending arbitration, and hereby agree to submit to the
                exclusive personal jurisdiction of the courts located within
                Sacramento County, California for such purpose. A request for
                interim measures shall not be deemed a waiver of the right to
                arbitrate.
              </p>
              <p>
                With the exception of SpotOn Reserve Parties (1) and (2) in this
                Section (prohibiting arbitration on a class or collective
                basis), if any part of this arbitration provision is deemed to
                be invalid, unenforceable, or illegal, or otherwise conflicts
                with the Rules and Procedures, then the balance of this
                arbitration provision shall remain in effect and shall be
                construed in accordance with its terms as if the invalid,
                unenforceable, illegal or conflicting part was not contained
                herein. If, however, either subpart (1) or (2) is found to be
                invalid, unenforceable, or illegal, then the entirety of this
                arbitration provision shall be null and void, and neither you
                nor SpotOn Reserve shall be entitled to arbitration. If for any
                reason a claim proceeds in court rather than in arbitration
                (including any claims brought by parties outside the United
                States or Canada), the dispute shall be exclusively brought in
                state or federal court located in Sacramento County, California.
                For more information on AAA, the Rules and Procedures, or the
                process for filing an arbitration claim, you may call AAA at
                800-778-7879 or visit the AAA website at http://www.adr.org.
              </p>
              <Title level={3}>23. Choice of Law.</Title>
              <p>
                This Agreement is made under and shall be governed by and
                construed in accordance with the laws of the State of
                California, U.S.A, consistent with the Federal Arbitration Act
                (to the extent permitted by applicable law), without giving
                effect to any principles that provide for the application of the
                law of another jurisdiction. You may also be entitled to certain
                consumer protection rights under the laws of your local
                jurisdiction and/or country of residency.
              </p>
              <Title level={2}>Privacy Policy</Title>
              <p>
                This Privacy Policy describes how SpotOn Transact’s “SpotOn
                Reserve” product (collectively, “SpotOn Reserve,” “we,” “our” or
                “us”) collects, uses, processes and shares personal information.
                This Policy applies to visitors and users (individually, “you”)
                of SpotOn Reserve’s websites, applications, social media
                accounts, and other services (collectively, our “Services”).
                Please note that your use of our Services is additionally
                subject to the SpotOn Reserve Terms of Use.
              </p>
              <p>
                SpotOn Reserve helps restaurants organize waitlists and
                reservations. This involves sharing personal information with
                third parties, including restaurants, restaurant groups, and
                restaurant affiliates, amongst others. In some cases, these
                third parties may collect personal information directly from you
                and share it with us.
              </p>
              <p>
                For more information about the information we share with third
                parties and the recipients of such information, please refer to
                the How We Share Your Information section of this Policy. Please
                note that this Policy does not otherwise cover the use of
                personal information by third parties or their services, and we
                do not take responsibility for their privacy practices.
              </p>
              <p>
                You may have certain rights or choices that relate to your
                personal information, including choices about how we share your
                personal information with others. For more information, please
                refer to the Your Choices and Rights section of this Policy.
              </p>
              <Title level={2}>OVERVIEW</Title>
              <Title level={3}>Information We Collect and Use</Title>
              <p>
                When you use or visit our Services, we collect information
                directly from you (e.g., when you search for or make an online
                reservation). In some cases, we also obtain personal information
                from third parties (e.g., restaurants, business partners, our
                group companies, or other third parties).
              </p>
              <Title level={3}>How We Use Your Information</Title>
              <p>
                We use your information to provide our Services, and for other
                purposes described below.
              </p>
              <Title level={3}>How We Share Your Information</Title>
              <p>
                We share your information with restaurants you engage with by
                making reservations or joining a wait list. We share information
                to provide and improve our Services, for our own business
                purposes, and for other purposes as described below.
              </p>
              <Title level={3}>How We Store and Protect Your Information</Title>
              <p>
                We maintain technical, administrative, and physical security
                measures designed to protect your information. We may retain
                your information for as long as your account is active and for a
                period of time thereafter to allow you to re-activate your
                account without loss of information, and as necessary for
                purposes described below.
              </p>
              <Title level={3}>Cookies</Title>
              <p>
                We use cookies and similar technologies to help provide our
                Services, offer you a more personalized user experience.
              </p>
              <Title level={3}>Your Choices and Rights</Title>
              <p>
                You have choices about your information. To access and update
                your information, you can log into your user profile at
                SpotOnReserve.com.
              </p>
              <Title level={3}>Links to Other Websites</Title>
              <p>
                Our Services may contain links to other websites or services
                that are not owned or controlled by SpotOn Reserve, including
                links to websites of restaurants and restaurant affiliates.
              </p>
              <Title level={3}>Children</Title>
              <p>
                Our Services are not directed at or intended for use by
                children, nor do we knowingly collect information from children
                under 18 years of age.
              </p>
              <Title level={3}>Changes to This Policy</Title>
              <p>
                We update this Policy from time to time, and changes will be
                effective when posted (as identified in the Policy).
              </p>
              <Title level={3}>How to Contact Us</Title>
              <p>
                If you have any questions about this Policy or the way your
                personal information has been used, please contact us at
                support@spoton.com.
              </p>
              <Title level={2}> I. INFORMATION WE COLLECT AND USE</Title>
              <p>
                “Personal information” is data that identifies, relates to,
                describes, can be used to contact, or could reasonably be linked
                directly or indirectly to you. For purposes of this Policy,
                there is no meaningful distinction between the terms “personal
                information” and “personal data.”
              </p>
              <Title level={3}>
                Personal Information We Collect Directly From You.
              </Title>
              <p>
                As you visit or use our Services, we collect the following
                categories of personal information directly from you:
              </p>
              <ul>
                <li>
                  <Title level={4}>Personal details</Title>
                  <p> (such as your name and birthday)</p>
                </li>
                <li>
                  <Title level={4}>Contact information</Title>
                  <p>(such as email address, mobile phone number)</p>
                </li>
                <li>
                  <Title level={4}>Dining information</Title>
                  <p>
                    {" "}
                    (such as primary dining city; current and past restaurant
                    reservation details; dining preferences; special restaurant
                    requests; dining activity, including frequency, restaurants,
                    restaurant type, meal type, and cancellations)
                  </p>
                </li>
                <li>
                  <Title level={4}>Account information</Title>
                  <p>(such as account settings and passwords)</p>
                </li>
                <li>
                  <Title level={4}>Billing information</Title>
                  <p>
                    (such as credit, debit, or other payment card information,
                    as may be required to use certain Services)
                  </p>
                </li>
                <li>
                  <Title level={4}>Your contacts</Title>
                  <p>
                    {" "}
                    (such as contact information of people you add to, or notify
                    of, your restaurant reservations through our Services)
                  </p>
                </li>
                <li>
                  <Title level={4}>Your preferences</Title>
                  <p>(including survey responses)</p>
                </li>
                <li>
                  <Title level={4}> Communications you send us</Title>
                  <p>
                    (such as customer support and other requests you make
                    through our Services and through restaurants)
                  </p>
                </li>
                <li>
                  <Title level={4}>Other information you may provide</Title>
                  <p>
                    (including other information you provide about yourself or
                    others through our Services or to which you provide us with
                    access via third-party platforms)
                  </p>
                </li>
              </ul>
              <p>
                You may choose not to provide some of the personal information
                described above. Please note, however, that many of our Services
                require some personal information to operate, so if you choose
                not to provide the personal information necessary to operate and
                provide you with a particular Service or feature of that
                Service, you may not be able to use that Service or feature.
              </p>
              <p>
                We do not proactively collect sensitive personal information,
                such as health-related information. However, our Services
                include text boxes that are designed for you to describe certain
                details about your dining preferences that you want restaurants
                to know. Please be aware that information you freely submit in
                these boxes may reveal to us or to the restaurants or restaurant
                affiliates with which we or they share information (as detailed
                in the How We Share Your Information section, below) certain
                information that may be considered sensitive personal
                information under applicable law (for example, about any
                allergies or dietary restrictions). We do not use this
                information for the purpose of marketing or advertising products
                to you. Sensitive personal information you voluntarily submit is
                processed on the basis of your consent, which you may revoke at
                any time by contacting us at support@SpotOn Reserve.com.
              </p>
              <Title level={3}>Personal Information Generated By Us.</Title>
              <p>
                As you use our Services, we generate certain personal
                information about you, including through automatic data
                collection and by inferences based on the information we collect
                about you and your activity. We may automatically collect
                information about your interactions with the Services or
                communications you receive (such as email) using certain
                technologies, such as cookies, web beacons and other
                technologies (see our Cookies Policy for more details). We
                generate the following categories of personal information about
                you:
              </p>
              <ul>
                <li>
                  <Title level={4}> Device information.</Title>
                  <p>
                    {" "}
                    When you visit or use our Services, we automatically collect
                    certain information about your device (e.g., your mobile
                    device, computer, or tablet), including information about
                    your hardware and software, device configuration, and nearby
                    networks. Such data may include data about your device
                    operating systems, browsers, and other software installed on
                    your device; device identifiers, such as IP address, IMEI
                    number, MAC address, or other device identifier; country of
                    origin, region and language settings; and information about
                    domain servers and wireless or network access points near
                    your device.
                  </p>
                </li>
                <li>
                  <Title level={4}>Usage and performance information</Title>
                  <p>
                    We also automatically collect personal information about
                    your usage of the Services, including information about your
                    searches or interactions with features of our Services;
                    sites or restaurant pages visited; booking path; access
                    times; and performance of our Services.
                  </p>
                </li>
                <li>
                  <Title level={4}>Location information</Title>
                  <p>
                    If you use our Services, we automatically collect generic
                    location information about you (such as city or
                    neighborhood) or, with your consent, precise geographic
                    location data from your mobile device when the app is
                    running and when it is not running, depending on the choices
                    you make when you are asked to consent to our collection of
                    location information. For example, we may receive this
                    information when you select restaurant search locations,
                    enter your local dining city in your account profile, when
                    you are in proximity to certain beacons, choose to publish
                    your location in reviews you leave for restaurants on the
                    Services, or in your comments or other communications with
                    us. We may use and store this information to provide and
                    improve features of our Services, for example, to tailor our
                    Services on a needs-based manner to better facilitate your
                    requests and to provide you with more relevant content about
                    restaurants in your dining city or cities you visit. Please
                    see the Your Rights and Choices section below for more
                    information about how to adjust your preferences, including
                    those related to location information.
                  </p>
                </li>
                <li>
                  <Title level={4}> Inferences about you.</Title>
                  <p>
                    We combine the information we collect, generate, or
                    otherwise obtain to draw inferences about your preferences
                    and interests in order to provide and personalize our
                    Services and tailor the offers we and our partners provide
                    to you.
                  </p>
                </li>
              </ul>
              <Title level={3}>
                Personal Information We Obtain from Third Parties.
              </Title>
              <p>
                We may also receive certain categories of personal information
                from third parties, such as restaurants, and other third
                parties, including individuals who have added you as a guest to
                their reservation. If you are an existing SpotOn Reserve
                customer, we will combine this information with information we
                collect through our Services and use and share it for the
                purposes described below. The categories of personal information
                we may obtain from third parties include:
              </p>
              <ul>
                <li>
                  <Title level={4}>Your name</Title>
                </li>
                <li>
                  <Title level={4}>
                    {" "}
                    Email address, mobile number or other contact information
                  </Title>
                </li>
                <li>
                  <Title level={4}>
                    Browsing, usage and advertising details,
                  </Title>
                  <p>
                    including how you use our Services and/or third-party
                    websites, applications, and services; and information
                    relating to your interactions with our advertising and
                    marketing materials
                  </p>
                </li>
                <li>
                  <Title level={4}> Purchase Information</Title>
                  <p>
                    including information from point-of-sale devices at certain
                    participating restaurants (such as items ordered, bill
                    total, and time spent at the restaurant); or purchase
                    information for other business partners and affiliates, such
                    as food delivery partners
                  </p>
                </li>
              </ul>
              <Title level={3}>Information from restaurants</Title>
              <p>
                including information that certain participating restaurants
                provide us about diners (for example, if you eat at a hotel
                restaurant as a hotel guest, restaurants are able to flag that
                you are a guest at that hotel, or if you eat at a chain of
                restaurants, those restaurants can flag whether you have a
                loyalty card or other information about interactions with those
                restaurants)
              </p>
              <Title level={2}>II. HOW WE USE YOUR INFORMATION</Title>
              <p>
                We use your personal information for the following purposes
                (“Purposes”), to:
              </p>
              <ul>
                <li>
                  <Title level={4}> Provide the Services,</Title>
                  <p>
                    {" "}
                    which includes providing you with the services, products,
                    and functionality offered through our Services and
                    fulfilling your requests, including but not limited to:
                    making reservations, paying for services or events, joining
                    waitlists, and notifying people you add to or notify of your
                    restaurant reservations through the Services
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Authenticate your account credentials and identify you,
                  </Title>
                  <p>
                    as necessary to log you in to the Services and ensure the
                    security of your account
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Communicate with you about your account or use of our
                    Services,
                  </Title>
                  <p>
                    products, and/or functionality; respond to, or follow up on,
                    your comments and questions; and otherwise provide customer
                    service (see below for more detail on Electronic
                    Communications)
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Operate and improve our Services and develop new products
                    and services,{" "}
                  </Title>
                  <p>
                    including using analytics to better understand how you use
                    our Services for purposes of product, website, application
                    and service development and to enhance the user experience
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Authenticate your credit or debit card account information
                  </Title>
                </li>
                <li>
                  <Title level={4}>
                    Provide services, products, and information to restaurants,
                  </Title>
                  <p>including restaurant groups and restaurant affiliates</p>
                </li>
                <li>
                  <Title level={4}>
                    Protect against, investigate, and deter fraudulent,
                    unauthorized, or illegal activity
                  </Title>
                </li>
                <li>
                  <Title level={4}>
                    Comply with our policies, procedures and legal obligations,
                  </Title>
                  <p>
                    including complying with law enforcement or government
                    authority requests, addressing litigation-related issues,
                    and exercising rights or obligations conferred by law
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    As otherwise consented to by you and as required or
                    permitted by applicable law.
                  </Title>
                  <p>
                    If you give your consent to any further use of personal
                    information, you can withdraw that consent at any time by
                    contacting us using the details set out below.
                  </p>
                </li>
              </ul>
              <p>
                You have choices about your personal information, and in some
                circumstances, you may have the right to opt-out or object to
                our uses of your personal information for these Purposes. For
                more information, or to exercise these or other rights (where
                available), see the Your Choices and Rights section below
              </p>
              <Title level={3}>Electronic Communications.</Title>
              <p>
                Consistent with the above Purposes and as permitted by
                applicable law, we may communicate with you via electronic
                messages, including email, text message, or mobile push
                notification to:
              </p>
              <ul>
                <li>
                  <Title level={4}>
                    Send you information relating to our products and Services.
                  </Title>
                  <p>
                    This may include reservation and waitlist confirmations,
                    reminders and updates, receipts, technical notices, updates,
                    security alerts, and support and administrative messages.
                  </p>
                </li>
              </ul>
              <p>
                With your consent, where required, we may contact you at the
                mobile phone number that you provide to us by way of direct dial
                calls, autodialed and prerecorded message calls, text messages
                and push notifications in connection with the above Purposes.
              </p>
              <Title level={3}>
                Our Role as Data Controller and Data Processor.
              </Title>
              <p>
                Under certain SpotOn Reserve programs, restaurants may engage us
                to provide them with certain processing services related to
                information owned or controlled by the restaurant. We seek
                assurances from restaurants that they will process information
                in accordance with applicable laws, but we are not responsible
                for any restaurant&quot;s use of information (including
                information used by its affiliates and service providers) for
                which it is an owner or controller. To learn more about how a
                restaurant may use such information, you should review its
                privacy notice.
              </p>
              <Title level={3}>Processing Bases and Consequences.</Title>
              <p>
                When we process your personal information, we rely on the
                following legal bases:
              </p>
              <ul>
                <li>
                  <Title level={4}>
                    Performance of the contract we have with you
                  </Title>
                  <p>
                    (such as if you use our Services to make a restaurant
                    reservation, we will use your information to carry out our
                    obligation to complete and administer your reservation by
                    sharing your information with the appropriate restaurant).
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Compliance with legal obligations to which we are subject{" "}
                  </Title>
                  <p>
                    (such as tax obligations, and when we are obliged to comply
                    with lawful requests from competent authorities such as law
                    enforcement).
                  </p>
                </li>
                <li>
                  <Title level={4}>To serve our legitimate interests </Title>
                  <p>
                    (such as tailoring your experience with our Services and for
                    fraud detection), provided that such processing does not
                    outweigh your rights and freedoms. The processing may also
                    be pursuant to other applicable legal bases for data
                    processing especially provisions set out under local law.
                  </p>
                </li>
              </ul>
              <p>
                Where we use personal information to meet our legitimate
                interests, we take steps to ensure that your rights with respect
                to your personal information are not infringed. You can contact
                us at support@spoton.com for more information about the steps we
                take to ensure these rights are not infringed. You also have the
                right to object to such processing as described in the Your
                Choices and Rights section below.
              </p>
              <ul>
                <li>
                  <Title level={4}>Consent</Title>
                  <p>
                    To the extent that a legal ground described above would not
                    apply to processing of your personal information by us, we
                    will seek your consent for such specific purpose in
                    accordance with applicable law (such as sending direct
                    marketing messages by electronic means, like email, without
                    an exception from the requirement to obtain consent).
                  </p>
                </li>
              </ul>
              <Title level={3}>Aggregate Information.</Title>
              <p>
                We aggregate personal information collected directly from you,
                information generated about you by us, and information obtained
                from third parties (with your consent, where required) with
                personal information collected about other users in order to
                produce general statistics that cannot be linked to you or any
                other specific user. Information that has been aggregated and
                de-identified is no longer considered “personal information” and
                may be subsequently used for any purpose.
              </p>
              <Title level={3}>Anonymized Information.</Title>
              <p>
                We may process information that cannot be linked to you or any
                other specific user using any means available to us, either
                because it was collected anonymously or has been subsequently
                anonymized. Information that is anonymous or has been anonymized
                is no longer considered “personal information” and may be
                subsequently used for any purpose.
              </p>
              <Title level={2}>III. HOW WE SHARE YOUR INFORMATION</Title>
              <p>
                {" "}
                We disclose the personal information we collect (or otherwise
                generate or obtain) as follows:{" "}
              </p>
              <ul>
                <li>
                  <Title level={4}>
                    With restaurants and restaurant affiliates.
                  </Title>
                  <p>
                    We share your information with restaurants and their
                    affiliates to provide the Services (such as connecting
                    diners with restaurants to make an online reservation and
                    sharing your dining activity and history, preferences,
                    requests, restrictions, and other information with the
                    restaurant) and for the restaurant or restaurant group’s own
                    purposes, which may include marketing or advertising
                    purposes (see below for more detail on Sharing with
                    Restaurants and Restaurant Affiliates.
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Messages and other communications you submit to restaurants.{" "}
                  </Title>
                  <p>
                    If you submit a message or other communication to a
                    restaurant through our Services, you authorize us to share
                    your message or communications with the relevant restaurant,
                    and you authorize the restaurant to respond to you through
                    our Services.
                  </p>
                </li>
                <li>
                  <Title level={4}>To process payments. </Title>
                  <p>
                    We require credit or debit card information to make payments
                    at certain restaurants, to secure your reservation, purchase
                    tickets to events, or purchase other products or services,
                    in which case we share your payment information with
                    restaurants, third-party payment processors, and other
                    third-party service providers (such as fraud detection
                    services) (see below for more detail and additional terms
                    regarding Sharing to Process Payment Information).
                  </p>
                </li>
                <li>
                  <Title level={4}>With other service providers. </Title>
                  <p>
                    We share information with third-party vendors, consultants,
                    and other service providers who perform services or
                    functions on our behalf (see below for more detail on
                    Sharing with Other Service Providers).
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    In the event of a corporate transaction.
                  </Title>
                  <p>
                    We may disclose or transfer your information to a third
                    party if we sell, transfer, divest, or disclose all or a
                    portion of our business or assets to another company in
                    connection with or during negotiation of any merger,
                    financing, acquisition, bankruptcy, dissolution,
                    transaction, or proceeding.
                  </p>
                </li>
                <li>
                  <Title level={4}>To protect rights and property. </Title>
                  <p>
                    We may disclose your information to third parties when we
                    believe in good faith that disclosure is necessary to
                    protect our rights, our property, the integrity of the
                    Services, personal safety, or the interests of you or any
                    other person, and to detect, prevent and/or otherwise
                    address fraud, risk management, security or technical
                    issues.
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    To comply with and as required by law.
                  </Title>
                  <p>
                    We may disclose your personal information to government
                    authorities or other relevant third parties in order to
                    comply with applicable legal requirements, judicial
                    proceedings, court orders, legal process, or lawful requests
                    from governmental authorities.
                  </p>
                </li>
                <li>
                  <Title level={4}>Aggregate information. </Title>
                  <p>
                    {" "}
                    We share aggregate statistical data for the improvement of
                    our Services. We may also share aggregate or de-identified
                    information with third parties at our discretion.
                  </p>
                </li>
                <li>
                  <Title level={4}>At your request.</Title>
                  <p>
                    We also share your information as directed or requested by
                    you, or subject to your consent.
                  </p>
                </li>
              </ul>
              <p>
                In some circumstances, you may have the right to opt-out or
                object to our sharing of your information with certain third
                parties. For more information, or to exercise these or other
                rights, see the Your Choices and Rights section below.
              </p>
              <Title level={3}>
                Sharing with Restaurants and Restaurant Affiliates as a
                controller of your personal information.
              </Title>
              <p>
                When you make a request through our Services or through a
                restaurant, such as a restaurant reservation, joining a
                restaurant waitlist, making a payment to a restaurant through
                our Services, purchasing an event ticket, or if you are a guest
                of the person making the request, we necessarily provide certain
                categories of your information to the restaurant to facilitate
                that request. For requests to dine, this information may include
                your name, profile, time and date of visit, party size, your
                phone number, your dining preferences, guest information, any
                special requests, information or comments that you choose to
                submit (if any), and your email address. Your information is
                provided to the restaurant, just as it would be if you contacted
                the restaurant directly. If you provide a mobile phone number in
                connection with your request, restaurants may send you text
                messages regarding your request. Some restaurants also require
                you to provide credit or debit card account information to
                secure your reservation. When you make a reservation at a
                restaurant that is part of a restaurant group, we may also share
                additional information about your dining activity at that
                restaurant or restaurant group in the past, your dining
                preferences and/or information that we collect from you and
                third parties that may be relevant to that reservation.
              </p>
              <p>
                In addition to providing you with more customized Services, we
                may receive certain information from participating restaurants
                from their Point of Sale terminals that we analyze to provide
                aggregate information to the restaurant about their customers.
              </p>
              <p>
                We also share with restaurants summary reports of feedback from
                diners. If you provide comments about a restaurant through our
                Services, these comments may be shared with or may be accessible
                by that restaurant, and the restaurant may respond to you. We
                will not tie your comments with other information that can
                identify you directly, but a restaurant may be able to tell who
                you are from your comments, particularly if you give your name
                in the comments or provide contact information, such as an email
                address.
              </p>
              <Title level={3}>
                Sharing with Restaurants and Restaurant Affiliates as a
                processor or service provider on their behalf.
              </Title>
              <p>
                We act as a processor or service provider for Restaurants and
                Restaurant Affiliates in certain circumstances as described
                above (see above for more detail on Our Role as a Data
                Controller and Data Processor in the section below). In
                providing these services, we may, at the direction of the
                restaurant, share certain of your information with the
                restaurant’s affiliated restaurants (such as affiliated brands),
                restaurant group and/or restaurants with the same brand or
                parent-brand (collectively, such restaurant’s “restaurant
                group”), or other entities associated with the restaurant (such
                as the parent entity of the restaurant group or affiliated
                hotels) and/or their service providers (collectively, the
                associated entities and service providers, the restaurant’s
                &quot;restaurant affiliates&quot;) under SpotOn Reserve’s
                programs for the following purposes:
              </p>
              <ul>
                <li>
                  <Title level={4}>Customized services:</Title>
                  <p>
                    restaurants may share your information (such as meal or
                    seating preferences or special occasions) with their
                    restaurant affiliates and restaurant group to enhance the
                    hospitality the restaurant group provides you when you dine
                    with them (such as trying to seat you by a window, if you
                    previously expressed a preference for window seating).
                  </p>
                </li>
                <li>
                  <Title level={4}>Marketing:</Title>
                  <p>
                    as permitted by applicable law, to perform analytics and
                    tailor marketing to you.
                  </p>
                </li>
                <li>
                  <Title level={4}>
                    Operations &amp; Service Improvements:{" "}
                  </Title>
                  <p>
                    to support operations, improve the restaurant’s table and
                    shift planning, improve their hospitality services,
                    including supporting a loyalty program you have chosen to
                    participate in. regarding Sharing to Process Payment
                    Information).
                  </p>
                </li>
              </ul>
              <p>
                For more information, please feel free to contact the
                restaurants with which you dine or book reservations, or contact
                us at support@SpotOnReserve.com. To learn more about your
                choices related to how we share your information with restaurant
                groups and restaurant affiliates under our programs, please see
                the Your Choices and Rights section below.
              </p>
              <Title level={3}>Sharing to Process Payment Information.</Title>
              <p>
                To use certain Services (such as to make reservations at certain
                restaurants; to make payments to certain restaurants or to
                secure reservations; and to purchase tickets to events, or other
                products or services), we require credit or debit card account
                information. When you submit your credit or debit card account
                information through our Services, we share that information with
                restaurants, third-party payment processors, and other
                third-party service providers (including, but not limited to,
                vendors who provide fraud detection services to us and other
                third parties) to the extent necessary to meet our contractual
                obligations to you (e.g., to secure your reservation or make a
                payment to a restaurant where required), to meet our legitimate
                interests in preventing fraud and other misuse of our platforms,
                or with your consent where this is required by law. In
                particular:
              </p>
              <ul>
                <li>
                  When you use a credit or debit card to secure a reservation
                  through our Services, we provide your credit or debit card
                  account information (including card number and expiration
                  date, but excluding the CVV number) to our third-party payment
                  service providers and the applicable restaurant.
                </li>
                <li>
                  When you initially provide your credit or debit card account
                  information through our Services in order to use our
                  restaurant payment services, we provide your credit or debit
                  card account information to our third-party payment service
                  providers. As explained in the SpotOn Reserve Terms of Use,
                  these third parties may store your credit or debit card
                  account information so you can use our restaurant payment
                  services through our Services in the future, to the extent
                  permitted by local law.
                </li>
                <li>
                  For information about the security of your credit or debit
                  card account information, see the How We Store and Protect
                  Your Information section below.
                </li>
              </ul>
              <Title level={3}>Sharing with Other Service Providers.</Title>
              <p>
                We may share information with third-party vendors, consultants,
                and other service providers who perform services or functions on
                our behalf (e.g., hosting or operating our Services, data
                collection, reporting, ad response measurement, site analytics,
                data analysis, delivering marketing messages and advertisements,
                processing credit card payments, and providing fraud detection
                services). We do not authorize these third parties to use or
                disclose your information for purposes other than for which it
                has been provided. We require these third parties to maintain
                and implement security measures to protect your information from
                unauthorized access or processing.
              </p>
              <Title level={2}>
                IV. HOW WE STORE AND PROTECT YOUR INFORMATION
              </Title>
              <p>
                SpotOn Reserve maintains commercially-reasonable technical,
                administrative, and physical security measures designed to
                protect your information from loss, misuse, unauthorized access,
                disclosure, alteration, and destruction.
              </p>
              <p>
                When your credit or debit card account information is being
                transmitted to our Services or through our Services, it will be
                protected by cryptographic protocols. To be clear, SpotOn
                Reserve does not itself store your credit or debit card account
                information, and we do not have direct control over or
                responsibility for your credit or debit card account
                information. We use third party payment processors that are the
                controllers of your credit card information. Our contracts with
                third parties that receive your credit or debit card account
                information require them to keep it secure and confidential.
              </p>
              <p>
                However, we cannot guarantee that transmissions of your credit
                or debit card account information or your other information will
                always be secure or that unauthorized third parties will never
                be able to defeat the security measures taken by SpotOn Reserve
                or our third-party service providers. Except to the extent that
                liability cannot be excluded or limited due to applicable law,
                we assume no liability or responsibility for disclosure of your
                information due to errors in transmission, unauthorized
                third-party access, or other causes beyond our control.
              </p>
              <p>
                You play an important role in keeping your information secure.
                You should not share your user name, password, or other security
                information for your SpotOn Reserve account with anyone. If we
                receive instructions using your user name and password, we will
                assume you have authorized the instructions. If you have reason
                to believe that your interaction with us is no longer secure
                (e.g., if you feel that the security of any account you might
                have with us has been compromised), please contact us
                immediately at{" "}
                <a href="mailto:support@spoton.com">support@spoton.com</a>.
              </p>
              <Title level={3}>Retention</Title>
              <p>
                We may retain your personal information for as long as your
                account is active and for a period of time thereafter to allow
                you to re-activate your account without loss of information. We
                may also retain your personal information as necessary to:
              </p>
              <ul>
                <li>
                  {" "}
                  Maintain logs and business records for analysis, security,
                  and/or audit purposes
                </li>
                <li>Comply with record retention requirements under the law</li>
                <li>Deal with any complaints regarding the Services; and</li>
                <li>
                  Comply with our legal obligations, protect or defend our
                  rights, resolve disputes and enforce our contracts
                </li>
              </ul>
              <Title level={2}>V. COOKIES</Title>
              <p>
                {" "}
                When you use or visit the Services, we collect information about
                your usage and activity using cookies, web beacons, and other
                technologies. Third parties may also view, edit, or set their
                own cookies. We and our third-party service providers, our group
                companies, and other business partners may also place web
                beacons for these third parties. The use of these technologies
                by third parties is subject to their own privacy policies and is
                not covered by this Policy, except as required by law. See our
                Cookie Policy for more details.
              </p>
              <Title level={2}>VI. YOUR CHOICES AND RIGHTS</Title>
              <Title level={3}>Email</Title>
              <p>
                If you no longer want to receive marketing and promotional
                emails from SpotOn Reserve, you may click on the “unsubscribe”
                link in such emails to opt-out of future marketing email
                communications. If you have a SpotOn Reserve account, you may
                also opt-out of marketing emails in your account settings.
                Please note that even if you opt-out of receiving marketing
                communications from one or all of our Services, we will still
                send you service-related communications, such as confirmations
                of any future reservations you make.
              </p>
              <Title level={3}>Text Messages</Title>
              <p>
                If you no longer want to receive text messages from SpotOn
                Reserve, reply <b>STOP</b> (or as otherwise instructed) to the
                text message. Doing so will disable your ability to receive
                certain dining experience notifications from certain
                restaurants.
              </p>
              <Title level={3}>Cookies</Title>
              <p>
                {" "}
                To exercise choices regarding cookies set through our websites
                or Services, as well as other types of online tracking and
                online advertising, see our Cookie Policy for more details.{" "}
              </p>
              <Title level={3}>Application Location</Title>
              <p>
                As explained in more detail in the Information We Collect and
                Use section above, we collect information about your location if
                you enable location services through the settings in your mobile
                device, or with your consent, as may be required by law. You can
                change the privacy settings of your device at any time to turn
                off the sharing of this location information with our Services.
                If you choose to turn off location services, this could affect
                certain features of our Services. If you have specific questions
                about the privacy settings of your device, we suggest you
                contact the manufacturer of your device or your mobile service
                provider for help.
              </p>
              <Title level={3}>Customized Dining Experiences.</Title>
              <p>
                As described in the Sharing with Restaurants and Restaurant
                Affiliates section of the privacy policy above, when you book
                with restaurants, we share your information with them to ensure
                your booking is recorded. These restaurants may share your
                dining information with their restaurant affiliates or broader
                restaurant group for the purpose of improving and personalizing
                your dining experience on future visits. SpotOn Reserve does not
                share personal information for uses other than personalizing
                your dining experience and for restaurants using SpotOn Reserve
                to use for other internal purposes, such as their internal
                analytics.
              </p>
              <Title level={3}>Access and Correction of Your Information</Title>
              <p>
                {" "}
                If you have created an online account with us and would like to
                update the information you have provided to us, you can access
                your account to view and update your information by logging in
                at SpotOnReserve.com. You may also contact us at
                <a href="mailto:support@spoton.com"> support@spoton.com</a>.
              </p>
              <Title level={3}>Legal Rights</Title>
              <p>
                Where applicable under local law, you may have the following
                rights regarding your personal information: the right to access
                personal information we hold, and in some situations, the right
                to have that personal information corrected or updated, erased,
                restricted, or delivered to you or a third party in a usable
                electronic format (the right to data portability). Where
                applicable, you may also object to how we use your personal
                information if the legal basis for processing that information
                is our legitimate interest. Where we are using your personal
                information on the basis of your consent, and where applicable
                under local law, you have the right to withdraw that consent at
                any time.
              </p>
              <p>
                If you wish to exercise legal rights you may have under
                applicable law, please submit your request to
                support@spoton.com. So that we can better process your request,
                please provide the email or mobile phone number you use to log
                into your SpotOn Reserve account. If you do not have a SpotOn
                Reserve account, please provide the email and/or mobile number
                you used to make requests.
              </p>
              <Title level={2}>VII. LINKS TO OTHER WEBSITES</Title>
              <p>
                Our Services contain links to other websites or services that
                are not owned or controlled by SpotOn Reserve, including links
                to websites of restaurants and restaurant affiliates. This
                Policy only applies to information collected by our Services. We
                have no control over these third-party websites, and your use of
                third party websites and features are subject to privacy
                policies posted on those websites. We are not responsible or
                liable for the privacy or business practices of any third-party
                websites linked to our Services. Your use of third parties&apos;
                websites linked to our Services is at your own risk, so we
                encourage you to read the privacy policies of any linked
                third-party websites when you leave one of our Services.
              </p>
              <Title level={2}>VIII. CHILDREN</Title>
              <p>
                Our Services are not directed at or intended for use by
                children. We do not knowingly collect information from, children
                under 18 years of age. If you become aware that your child or
                any child under your care has provided us with information
                without your consent, please contact us at{" "}
                <a href="mailto:support@spoton.com"> support@spoton.com</a>.
              </p>
              <Title level={2}>IX. CHANGES TO THIS POLICY</Title>
              <p>
                {" "}
                Except to the extent limited by applicable law, we will update
                this Privacy Policy from time to time to reflect changes in our
                privacy practices, legal requirements, and other factors by
                prominently posting notice of the update on our Services.
                Changes to our Privacy Policy will be effective when posted and
                the new effective date will be identified.{" "}
              </p>
              <p>
                If we make any changes to the Privacy Policy that materially
                impact previously collected personal information about you, we
                will make reasonable efforts to provide notice and obtain
                consent to any such changes as may be required by law.
              </p>
              <p>
                {" "}
                To request a copy of this Policy, or to request a copy of the
                Privacy Policy in place at the time you signed up for an
                account, please contact us at the details below.
              </p>
              <Row>
                <Col>
                  <Title id="contact" level={2}>
                    X. HOW TO CONTACT US{" "}
                  </Title>
                  <p>
                    If you have any questions about this Policy or the way in
                    which your personal information has been used, please
                    contact us by email at support@spoton.com or by postal mail
                    at:
                  </p>
                  <p>
                    SpotOn Transact, LLC <br /> 100 California St, 9th Floor{" "}
                    <br /> San Francisco, CA 94111 <br /> 24/7 Phone:
                    <a href="tel:+18778144102">
                      &nbsp; 877-814-4102
                    </a> <br /> c/o Legal Counsel
                    <br /> Email:
                    <a href="mailto:Support@SpotOn.com">
                      {" "}
                      &nbsp; Support@SpotOn.com
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary 
                target="/legal/user-terms" 
                ctaTitle="User Terms" 
              />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
               <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default ReserveTerms;
